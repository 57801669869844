import AssetsTable from 'components/Assets/AssetsTable'
import { Banner, HighlightArticleItem, HighlightArticleItemType, HighlightArticles, LandingMarketsWidget, LatestNews, LatestUpdateArticles, MarketStatKline, MarketStats, PoweredByBlock } from 'components/Landing'
import { POWERED_OPTIONS } from 'components/Landing/PoweredByBlock/PoweredByBlock.constants'
import { Layout } from 'components/Layout'
import { TickersList } from 'components/TickersList'
import {
  appTitle,
  convertArticleFromStrapiArticle,
  convertAssetArticleFromStrapi,
  convertAssetFromStrapiAsset,
  convertCategoryFromStrapiCategory,
  DeviceType,
  getDeviceTypeFromHeaders,
  getHeaderNavigation,
} from 'helpers'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Article, Asset, AssetArticle, Category } from 'types'
import { ASSETS_PER_DESKTOP_PAGE, ASSETS_PER_MOBILE_PAGE, DEFAULT_LOCALE } from '../constants'
import { analytics } from '../helpers/analytics'
import { Market, useSetMobileDevice } from 'web-sdk'
import { fetchArticles, fetchAssetArticles, fetchAssets, fetchCategories } from 'hooks'
import { getPublicConfig } from 'configs/app'
import { convertPlainText } from 'helpers/convertPlainText'
import { getAbsoluteUrl } from 'helpers/getAbsoluteUrl'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { getAlternateLink } from 'helpers/getAlternateLink'
import { getLocalizedArticle } from 'helpers/getLocalizedArticle'
import { getLocalizedAssetArticle } from 'helpers/getLocalizedAssetArticle'
import { removeLocalizations } from 'helpers/removeLocalizations'

interface HomeProps {
  articles: Article[]
  pinnedArticleIds: number[]
  assetArticles: AssetArticle[]
  assetTableData: any
  strapiUrl: string
  categories: Category[]
  topGainers: Asset[]
  topLosers: Asset[]
  cgApiIds: string[]
  randomBannerIndex: number
  /**
   * Response from server for detecting mobile device
   */
  responseIsMobile?: boolean
}

/**
 * List of pinned articles id in default locale (en)
 */
const PINNED_ARTICLES = [8199]

const Home: React.FC<HomeProps> = ({
  articles,
  pinnedArticleIds,
  assetArticles,
  assetTableData,
  strapiUrl,
  categories,
  topGainers,
  topLosers,
  cgApiIds,
  randomBannerIndex,
  responseIsMobile,
}: HomeProps): JSX.Element => {
  const [marketCharts, setMarketCharts] = useState<{ asset: string, data: MarketStatKline[] }[]>([])
  const [highlightArticleList, setHighlightArticleList] = useState<HighlightArticleItem[]>(
    [
      ...articles.map(a => ({ type: 'article' as HighlightArticleItemType, article: a, isPinned: pinnedArticleIds.includes(a.id) })),
      ...assetArticles.map(a => ({ type: 'assetArticle' as HighlightArticleItemType, article: a, isPinned: false }))
    ]
  )
  
  const router = useRouter()
  const isMobile = useSetMobileDevice()
  const isTablet = useSetMobileDevice(false, 1026)
  const { t } = useTranslation('common')

  const publicRuntimeConfig = getPublicConfig()

  useEffect(() => {
    const fetchMarketCharts = async () => {
      try {
        const marketCharts: any[] = await Promise.all(cgApiIds.map(async (cg_api_id) => {
          const marketChartResponse = await fetch(`${publicRuntimeConfig.rootUrl}/api/v1/token_listing/public/market-chart/${cg_api_id}`)
          const marketChartRaw: Number[][] = await marketChartResponse.json()
          const marketChartData = marketChartRaw.length && marketChartRaw.slice(-20).map(item => {
            return {
              timestamp: +item[0],
              price: item[1],
            }
          })

          return {
            asset: cg_api_id,
            data: marketChartData,
          }
        }))

        setMarketCharts(marketCharts)
      } catch (error) {
        console.error('Error fetching market charts', error)
      }
    }

    fetchMarketCharts()
  }, [cgApiIds])

  useEffect(() => {
    analytics({ type: 'pageView', name: 'main_page_view' })
  }, [router.asPath])

  const mainContent = React.useMemo(() => {
    return (
      <div>
        <div>
          <TickersList />
        </div>

        <div className='mb-[62px] mx-auto 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center'>
          <div className="flex mt-9 mx-auto 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center">
            <HighlightArticles articles={highlightArticleList} isMobile={!!isMobile} isTablet={!!isTablet} />
          </div>
          <div className="flex mt-9 mx-auto 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center">
            <LatestUpdateArticles articles={articles} isMobile={!!isMobile} isTablet={!!isTablet} />
          </div>
          <div className="flex mt-9 mx-auto 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center">
            <MarketStats topGainers={topGainers} topLosers={topLosers} marketCharts={marketCharts} isMobile={!!isMobile} isTablet={!!isTablet} randomBannerIndex={randomBannerIndex} />
          </div>
        </div>

        {/* <div className="flex mt-9 flex-col mb-6 lg:mb-[62px] lg:flex-row mx-auto 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center">
          <div className="4xl:w-full 3xl:w-full 2xl:w-[735px] xl:w-[500px]">
            <AssetsTable
              strapiUrl={strapiUrl}
              assets={assetTableData.assets}
              start={assetTableData.start}
              end={assetTableData.end}
              total={assetTableData.total}
              lastPage={assetTableData.lastPage}
              rowsPerPage={assetTableData.rowsPerPage}
              isMobile={isMobile === undefined ? !!responseIsMobile : !!isMobile}
            />
          </div>
          <div className="3xl:max-w-[452px] 2xl:max-w-[390px] xl:max-w-[390px] lg:ml-[30px] lg:max-w-[452px]">
            <PoweredByBlock items={POWERED_OPTIONS} />
            <LatestNews articles={articles} responseIsMobile={responseIsMobile} />
            {!isTablet && !isMobile && <Banner />}
            {isMobile && <Banner />}
            <LandingMarketsWidget />
            {isTablet && !isMobile && <Banner />}
          </div>
        </div> */}
      </div>
    )
  }, [isMobile, isTablet, responseIsMobile, assetTableData, articles, marketCharts])

  const actionButtonProps = {
    text: t('header.button.wallet'),
    link: 'https://wallet.yellow.com',
  }

  return (
    <>
      <Head>
        <link rel="canonical" href={getAbsoluteUrl('/')} />
        {getAlternateLink('/')}
        <meta name="description" content={t('page.home.meta.description')} />
        <title>{appTitle(t('page.home.title'))}</title>
        <meta property="og:url" content={getAbsoluteUrl('/')} />
        <meta property="og:title" content={appTitle(t('page.home.title'))} />
        <meta property="og:description" content={t('page.home.meta.description')} />
        <meta property="og:site_name" content="yellow.com" />
        <meta name="twitter:site" content="twitter.com/Yellow" />
        <meta name="twitter:creator" content="@Yellow" />
      </Head>
      <Layout
        actionButtonProps={actionButtonProps}
        headerOptions={{
          ...getHeaderNavigation(categories),
          responseIsMobile,
        }}
      >
        <div className="bg-main-background-color">{mainContent}</div>
      </Layout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context: any) => {
  context.res.setHeader('Cache-Control', 'public, max-age=600, stale-while-revalidate=59')

  const { locale } = context
  const randomBannerIndex = Math.round(Math.random())

  const isMobile = getDeviceTypeFromHeaders(context.req) != DeviceType.Desktop
  const rowsPerPage = isMobile ? ASSETS_PER_MOBILE_PAGE : ASSETS_PER_DESKTOP_PAGE
  const publicRuntimeConfig = getPublicConfig()

  const [assetsResponse, topGainerAssetsResponse, topLoserAssetsResponse, articlesResponse, assetArticlesResponse, categoriesResponse, pinnedArticlesResponse] = await Promise.all([
    fetchAssets({
      pagination: { page: 1, pageSize: rowsPerPage },
      sort: 'market_cap:desc',
      populate: ['logo'],
      fields: ['code', 'name', 'id', 'cg_api_id', 'price_change_24h', 'volume_24h', 'market_cap', 'price'],
    }),
    fetchAssets({
      filters: { price_change_24h: { $gte: 0 } },
      pagination: { page: 1, pageSize: 5 },
      sort: 'market_cap:desc',
      populate: ['logo'],
      fields: ['code', 'name', 'cg_api_id', 'price_change_24h', 'volume_24h', 'market_cap', 'price'],
    }),
    fetchAssets({
      filters: { price_change_24h: { $lt: 0 } },
      pagination: { page: 1, pageSize: 5 },
      sort: 'market_cap:desc',
      populate: ['logo'],
      fields: ['code', 'name', 'cg_api_id', 'price_change_24h', 'volume_24h', 'market_cap', 'price'],
    }),
    fetchArticles({
      sort: 'publishedAt:desc',
      populate: {
        asset: true,
        tags: true,
        image: true,
        localizations: {
          populate: ['asset', 'tags', 'image'], // Ensure image in localizations is populated
        },
      },
      fields: ['title', 'description', 'slug', 'content', 'author', 'publishedAt'],
      pagination: { limit: 5 },
      locale: locale,
    }),
    fetchAssetArticles({
      sort: 'publishedAt:desc',
      populate: {
        asset: true,
        tags: true,
        image: true,
        localizations: {
          populate: ['asset', 'tags', 'image'], // Ensure image in localizations is populated
        },
      },
      filters: { asset: { code: { $notNull: true } } },
      fields: ['title', 'description', 'slug', 'content', 'author', 'type', 'publishedAt'],
      pagination: { limit: 5 },
      locale: locale,
    }),
    fetchCategories({
      populate: {
        localizations: true,
      },
      locale: locale,
    }),
    fetchArticles({
      filters: { id: { $in: PINNED_ARTICLES } },
      sort: 'publishedAt:desc',
      populate: {
        asset: true,
        tags: true,
        image: true,
        localizations: {
          populate: ['asset', 'tags', 'image'], // Ensure image in localizations is populated
        },
      },
      fields: ['title', 'description', 'slug', 'content', 'author', 'publishedAt'],
      pagination: { limit: 5 },
      locale: DEFAULT_LOCALE,
    }),
  ])

  const pagination = assetsResponse.meta.pagination
  const assets = assetsResponse.data.map(convertAssetFromStrapiAsset)
  const topGainers = topGainerAssetsResponse.data.map(convertAssetFromStrapiAsset).sort((a, b) => (b.price_change_24h || 0) - (a.price_change_24h || 0))
  const topLosers = topLoserAssetsResponse.data.map(convertAssetFromStrapiAsset).sort((a, b) => (a.price_change_24h || 0) - (b.price_change_24h || 0))
  const uniqueAssets = Array.from(new Set(Array.from([...topGainers, ...topLosers], (asset) => asset.cg_api_id)))
  
  const articles = articlesResponse.data?.map(convertArticleFromStrapiArticle)
  const pinnedArticles = pinnedArticlesResponse.data?.map(convertArticleFromStrapiArticle)
  const localizedArticles = articles.map((article) => getLocalizedArticle(article, locale))
  const localizedPinnedArticles = pinnedArticles.map((article) => getLocalizedArticle(article, locale))
  const pinnedArticleIds = localizedPinnedArticles.map((article) => article.id)
  const allLocalizedArticles = [...localizedArticles.filter((article) => !pinnedArticleIds.includes(article.id)), ...localizedPinnedArticles]
  
  const assetArticles = assetArticlesResponse.data?.map(convertAssetArticleFromStrapi)
  const localizedAssetArticles = assetArticles.map((article) => getLocalizedAssetArticle(article, locale))
  const localizedCategories = categoriesResponse.data.map(convertCategoryFromStrapiCategory)

  const articlesWithCutContent = allLocalizedArticles?.map((article: Article) => {
    return removeLocalizations({
      ...article,
      content: convertPlainText(article.content, true, 150),
    })
  })

  const assetArticlesWithCutContent = localizedAssetArticles?.map((article: AssetArticle) => {
    return removeLocalizations({
      ...article,
      content: convertPlainText(article.content, true, 150),
    })
  })

  return {
    props: {
      ...(await serverSideTranslations(context.locale || DEFAULT_LOCALE, ['common'])),
      articles: articlesWithCutContent || [],
      pinnedArticleIds: pinnedArticleIds,
      assetArticles: assetArticlesWithCutContent || [],
      categories: localizedCategories || null,
      strapiUrl: publicRuntimeConfig.strapiUrl,
      assetTableData: {
        assets,
        start: 0,
        end: rowsPerPage - 1,
        total: pagination.total ?? 0,
        lastPage: pagination.pageCount,
        rowsPerPage,
      },
      topGainers,
      topLosers,
      cgApiIds: uniqueAssets,
      randomBannerIndex: randomBannerIndex,
      responseIsMobile: isMobile,
    },
  }
}

export default Home
